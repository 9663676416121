/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Od lipca 2021 roku  do czerwca 2022 roku trwała inwestycja budowy trzech dróg wraz z infrastrukturą towarzyszącą w miejscowości Niemcz, na terenie gminy Osielsko."), "\n", React.createElement(_components.p, null, "Roboty budowlane składały się z trzech zadań, w ramach których zostały wykonane następujące czynności:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Budowa ul. Kopernika w Niemczu ( łączna długość 1+317,00 m ),"), "\n", React.createElement(_components.li, null, "Budowa ul. Marco Polo w Niemczu (długość 0+431,21 m),"), "\n", React.createElement(_components.li, null, "Budowa ul. Olimpijczyków w Niemczu (długość 0+343,52 m)"), "\n"), "\n", React.createElement(_components.p, null, "W ramach budowy wyżej wymienionych ulic wykonano między innymi:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "jezdnie o nawierzchni asfaltowej"), "\n", React.createElement(_components.li, null, "zatoki autobusowe z bruku kamiennego"), "\n", React.createElement(_components.li, null, "ścieżki pieszo - rowerowe o nawierzchni asfaltowej"), "\n", React.createElement(_components.li, null, "zjazdy indywidualne z kostki betonowej  oraz dojścia do furtek o szer. dostosowanej do istniejących bram,"), "\n", React.createElement(_components.li, null, "rowy przydrożne, wpusty deszczowe,"), "\n", React.createElement(_components.li, null, "przebudowę sieci telekomunikacyjnych, sieci wod-kan"), "\n", React.createElement(_components.li, null, "montaż latarni oświetleniowych LED"), "\n"), "\n", React.createElement(_components.p, null, "Prace zostały wykonane w sposób należyty, zgodnie z projektem oraz sztuką budowlaną, co zostało potwierdzone wystawionymi przez zamawiającego referencjami."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
